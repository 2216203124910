let module = false;

//let context = require.context('@/overrides/client/assets/', true, /frameworks.js/);
context = require.context('@/', true, /\/overrides\/client\/assets\/frameworks\.js/);

context.keys().forEach(key => {
    if ('./overrides/client/assets/frameworks.js' === key) {
        module = context(key);
    }
});

if ( ! module) {
    module = require("@/client/assets/frameworks.js");
}


module = false;

//context = require.context('@/overrides/client/assets/', true, /scss.js/);
context = require.context('@/', true, /\/overrides\/client\/assets\/scss\.js/);


context.keys().forEach(key => {

    if ('./overrides/client/assets/scss.js' === key) {
        module = context(key);
    }
});


if ( ! module) {
    module = require("@/client/assets/scss.js");
}




