let module = false;

//let context = require.context('@/overrides/client/assets/', true, /\.js/);
let context = require.context('@/', true, /\/overrides\/client\/assets\/.*\.js/);

context.keys().forEach(key => {
    if ('./overrides/client/assets/assets.js' === key) {
        module = context(key);
    }
});

if ( ! module) {
    module = require("@/client/assets/assets.js");
}

export default module.default;
